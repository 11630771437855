<template>
  <div>
    <transition name="fadeleft">
      <main-text key="step3a" :text="texts.step3a" v-if="step==2 && current<1"></main-text>
<!--       <div key="step2y" v-else-if="step==0 && current<2"></div> -->
      <div class="text-content sections" v-else-if="step==2">
        <div class="sec1">
          <p v-html="texts.step3b.title1"></p>
          <div class="sec-links"><a href="" v-html="texts.step3b.type1" v-on:click.prevent=""></a></div>
        </div>
        <div class="sec2">
          <p v-html="texts.step3b.title2"></p>
          <div class="sec-links"><a href="" v-html="texts.step3b.type2" v-on:click.prevent=""></a></div>
          <div class="sec-links"><a href="" v-html="texts.step3b.type2a" v-on:click.prevent=""></a></div>
        </div>
      </div>
      
    </transition>
    

  </div>
</template>

<script>
//import texts from '../texts.json';
texts=window.texts;
export default {
  props: ["contentLoaded", "step", "current"],
  data: ()=>({
    texts: texts,
  }),
}
</script>
