var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "end-message", class: { active: _vm.active } },
    [
      _c("div", [
        _c("div", { staticClass: "fline" }, [
          _vm._v(_vm._s(_vm.texts.endText1))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sline" }, [
          _vm._v(_vm._s(_vm.texts.endText2))
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "target",
            class: { visible: _vm.targetVisible },
            attrs: {
              href: "https://giving.broadinstitute.org/fasi",
              target: "_blank"
            }
          },
          [_vm._v("Learn more")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }