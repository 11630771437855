var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      class: { "final-step": _vm.step == 3 },
      style: {
        transform: " translate(-50%, -50%) scale(" + _vm.scale + ")",
        "transform-origin": "50% 50%"
      }
    },
    [
      _c("div", { staticClass: "logo", class: { visible: _vm.initDone } }, [
        _vm._v("FOOD ALLERGY SCIENCE INITIATIVE")
      ]),
      _vm._v(" "),
      _c(
        "footer",
        { class: { visible: _vm.initDone } },
        [
          _c(
            "a",
            {
              staticClass: "scroll",
              class: { visible: _vm.progress == 0, back: _vm.step == 3 },
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.step == 3 ? (_vm.step = 0) : _vm.nextStep()
                }
              }
            },
            [_c("span"), _vm._v(_vm._s(_vm.step == 3 ? "Beginning" : "Scroll"))]
          ),
          _vm._v(" "),
          _c("progress-bar", { attrs: { progress: _vm.progress } }),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "close",
          class: { visible: _vm.initDone, active: _vm.closeActive },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.doClose($event)
            }
          }
        },
        [
          _c("em"),
          _vm._v(" "),
          _c("span", [_vm._v("Are you sure you'd like to quit?")])
        ]
      ),
      _vm._v(" "),
      _c("step1-titles", {
        attrs: {
          contentLoaded: _vm.contentLoaded,
          step: _vm.step,
          current: _vm.current,
          systemSelected: _vm.systemSelected
        }
      }),
      _vm._v(" "),
      _c("step2-titles", {
        attrs: {
          contentLoaded: _vm.contentLoaded,
          step: _vm.step,
          current: _vm.current
        }
      }),
      _vm._v(" "),
      _c("step3-titles", {
        attrs: {
          contentLoaded: _vm.contentLoaded,
          step: _vm.step,
          current: _vm.current
        }
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "stepsanim", mode: "out-in" } },
        [
          _vm.step == 0
            ? _c("step1-elements", {
                attrs: {
                  contentLoaded: _vm.contentLoaded,
                  current: _vm.current,
                  frame: _vm.frame,
                  systemSelected: _vm.systemSelected
                }
              })
            : _vm.step == 1
            ? _c("step2-elements", {
                attrs: {
                  contentLoaded: _vm.contentLoaded,
                  current: _vm.current,
                  frame: _vm.frame
                }
              })
            : _vm.step == 2
            ? _c("step3-elements", {
                attrs: { current: _vm.current, frame: _vm.frame }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.step == 3 ? _c("step-final") : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "popupanim" } },
        [
          _vm.person != null
            ? _c("popup-person", { attrs: { person: _vm.person } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "lmore",
        attrs: {
          href: "https://giving.broadinstitute.org/fasi",
          target: "_blank"
        }
      },
      [_c("span"), _vm._v("Learn more")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }