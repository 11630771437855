<template>
  <div class="text-content onpg1">
    <h1 v-html="text.title"></h1>
    <h2 v-html="text.subtitle" v-if="text.subtitle"></h2>
    <p v-html="text.text"></p>
  </div>
</template>

<script>
export default {
  props: ["text"],
}
</script>
