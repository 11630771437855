<template>
  <div class="step step-2 animel" :class="stepClass">
    <div class="animel el-dots"><em></em><em></em><em></em><em></em><em></em><em></em></div>
    <div class="animel el-neuron n1"><div>
      <step1-neuron class="animel" :inflamation="inflamation" />
    </div></div>
    <div class="animel el-icl i1"><div>
      <step1-ilc class="animel" :inflamation="inflamation" :handle="1-inflamation" :peptide="true" />
    </div></div>
    <div class="animel el-neuron n2"><div>
      <step1-neuron class="animel" :inflamation="inflamation" />
    </div></div>
    <div class="animel el-icl i2"><div>
      <step1-ilc class="animel" :inflamation="inflamation" :handle="1-inflamation" :peptide="true" />
    </div></div>
    <div class="animel el-neuron n3"><div>
      <step1-neuron class="animel" :inflamation="inflamation" />
    </div></div>
    <div class="pill">
      <div class="animel pill-dot d1"></div>
      <div class="animel pill-dot d2"></div>
      <div class="animel pill-dot d3"></div>
      <div class="animel pill-half h1"></div>
      <div class="animel pill-half h2"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["contentLoaded", "current", "frame"],
  data: ()=>({
    inflamation: 1,
  }),
  methods: {
    animate(property, reverse, limit, increment) {
      var interval=setInterval(()=>{
        if (!reverse) {
          this[property]+=increment;
          if (this[property]>=limit) {
            this[property]=limit;
            clearInterval(interval);
          }
        } else {
          this[property]-=increment;
          if (this[property]<=limit) {
            this[property]=limit;
            clearInterval(interval);
          }
        }
      }, 16);
    },
  },
  mounted: function() {
    if (this.current>=2)
      this.inflamation=0;
  },
  watch: {
    frame(newFrame, oldFrame) {
    },
    current(newCurrent, oldCurrent) {
      if (newCurrent==2 && newCurrent>oldCurrent)
        this.animate("inflamation", true, 0, 0.003);
      if (oldCurrent==2 && newCurrent<oldCurrent)
        this.animate("inflamation", false, 1, 0.003);
      if (newCurrent==3 && newCurrent>oldCurrent)
        this.animate("inflamation", true, 0, 0.003);
      if (oldCurrent==3 && newCurrent<oldCurrent)
        this.animate("inflamation", false, 0, 0.003);
    },
  },
  computed: {
    stepClass() {
      var result="";
      for(var i=0;i<=this.current;i++)
        result+=" step-2-"+i;
      for(var i=0;i<=this.frame;i++)
        result+=" step-2-"+this.current+"-"+i;
      return result;
    },
  },
}
</script>
