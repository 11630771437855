var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fadeleft" } },
        [
          _vm.step == 1 && _vm.current < 1
            ? _c("main-text", {
                key: "step2a",
                attrs: { text: _vm.texts.step2a }
              })
            : _vm.step == 1 && _vm.current < 2
            ? _c("narrow-text", {
                key: "step2b",
                attrs: { text: _vm.texts.step2b }
              })
            : _vm.step == 1 && _vm.current < 3
            ? _c("narrow-text", {
                key: "step2c",
                attrs: { text: _vm.texts.step2c }
              })
            : _vm.step == 1 && _vm.current < 4
            ? _c("narrow-text", {
                key: "step2d",
                attrs: { text: _vm.texts.step2d }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "example-text",
          class: { visible: _vm.step == 1 && _vm.current == 4 }
        },
        [
          _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } }),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.text) } })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }