<template>
  <div class="bg">
    <div class="over1" :class="{active: step>0}">
      <div class="over2" :style="over2bg"></div>
    </div>
    <div class="over3" :class="{active: step>3}"></div>
    <div class="over4" :class="{active: step>4}"></div>
  </div>
</template>

<script>
export default {
  data: ()=>({
    step: 0,
    over2Progress: 0,
  }),
  computed: {
    over2bg() {
      if (this.over2Progress==0) {
        return "";
      }
      var over2ProgressNormalized=Math.max(0, this.over2Progress*1.2-0.2);
      var mapColor=(a, b, step)=>{
        step=Math.max(0, Math.min(1, step));
        return [
          Math.round(a[0]+(b[0]-a[0])*step),
          Math.round(a[1]+(b[1]-a[1])*step),
          Math.round(a[2]+(b[2]-a[2])*step),
        ];
      };
      var startColor=[46,101,178];
      var endColor=[169,55,134];
      var firstColor=mapColor(startColor, endColor, Math.pow(1-over2ProgressNormalized, 4)).join(",");
      var secondColor=mapColor(startColor, endColor, 1-Math.pow(over2ProgressNormalized, 4)).join(",");
      var gradient="radial-gradient(circle at 50% 0%, rgb("+firstColor+") 0%, rgb("+secondColor+") 100%)";
      var gradient="radial-gradient(rgb("+firstColor+"), rgb("+secondColor+")) 50% 100% / 200% 200%";
      return {background: gradient};
    },
  },
  watch: {
    step: {
      immediate: true,
      handler() {
        this.over2ProgressAnim();
      },
    },
  },
  methods: {
    over2ProgressAnim() {
      clearInterval(this.over2PInt);
      var startTime=(new Date()).getTime();
      this.over2PInt=setInterval(()=>{
        var newTime=(new Date()).getTime();
        var timePassed=newTime-startTime;
        startTime=newTime;
        if (this.step<=1) {
          if (this.over2Progress==0) {
            clearInterval(this.over2PInt);
            return;
          }
          this.over2Progress-=0.0025/16*timePassed;
          if (this.over2Progress<0)
            this.over2Progress=0;
        } else if (this.step==2) {
          if (this.over2Progress==0.5) {
            clearInterval(this.over2PInt);
            return;
          }
          if (this.over2Progress>0.5)
            this.over2Progress-=0.0025/16*timePassed;
          else
            this.over2Progress+=0.0025/16*timePassed;
        } else {
          if (this.over2Progress==1) {
            clearInterval(this.over2PInt);
            return;
          }
          this.over2Progress+=0.0025/16*timePassed;
          if (this.over2Progress>1)
            this.over2Progress=1;
        }
      }, 16);
    }
  },
  mounted() {
    window.igBackground=this;
  },
}
</script>
