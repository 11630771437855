<template>
  <div class="step step-1 animel" :class="stepClass">
    <human-figure :intestine="false" class="animel el-human" :inflamation="inflamation" :progress="cough" :isCough="isCough" :systemSelected="systemSelected" :inflamation4="inflamation4" />
    <div :class="{loaded: contentLoaded}" class="animel el-egg"></div>
    <div :class="{loaded: contentLoaded}" class="animel el-milk"></div>
    <div :class="{loaded: contentLoaded}" class="animel el-peanut"></div>
    <div :class="{loaded: contentLoaded}" class="animel el-peanut-1"></div>
    <div :class="{loaded: contentLoaded}" class="animel el-peanut-2"></div>
    <div :class="{loaded: contentLoaded}" class="animel el-peanut-3"></div>
    <human-figure :intestine="true" class="animel el-human" :inflamation="inflamation" :progress="cough" :isCough="isCough" :systemSelected="systemSelected" />
    <div class="animel el-dot"></div>
    <div class="el-triangle-rotate">
      <step1-triangle class="animel el-triangle" />
    </div>
    <div class="animel el-circle" ref="elCircle">
      <div class="animel el-lining" :style="{transform: mouseTransform}"><span>Gut Lining</span><em></em></div>
      <div class="animel el-food" :style="{transform: mouseTransform}"><span>Food<br />Allergens</span><em></em></div>
      <div class="animel el-neuron" :style="{transform: mouseTransform+' translate(60px, 40px) scale(1.4)'}"><span>Neuron</span>
        <step1-neuron class="animel" :inflamation="inflamation2" />
      </div>
      <div class="animel el-peptide" :style="{transform: mouseTransform}"><span>Neuro<br />Peptide</span><em></em></div>
      <div class="animel el-icl" :style="{transform: mouseTransform}"><span>ILC2 Cell</span><span class="animel">Neuro<br />Peptide<br />Receptor</span>
        <step1-ilc class="animel" :inflamation="inflamation3" />
      </div>
    </div>
    
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: ["contentLoaded", "current", "frame", "systemSelected"],
  data: ()=>({
    backwards: false,
    inflamation: 0,
    inflamation2: 0,
    inflamation3: 0,
    inflamation4: 0,
    cough: 0,
    isCough: false,
    mouseX: 0,
    mouseY: 0,
    mouseStep: 0,
  }),
  methods: {
    animate(property, reverse, limit, increment) {
      var startTime=(new Date()).getTime();
      var interval=setInterval(()=>{
        var newTime=(new Date()).getTime();
        var timePassed=newTime-startTime;
        startTime=newTime;
        var increment2=increment*timePassed/16;
        if (!reverse) {
          this[property]+=increment2;
          if (this[property]>=limit) {
            this[property]=limit;
            clearInterval(interval);
          }
        } else {
          this[property]-=increment2;
          if (this[property]<=limit) {
            this[property]=limit;
            clearInterval(interval);
          }
        }
      }, 16);
    },
    pulsate(property, max, min, increment) {
      var current=this.current+' '+this.frame;
      var direction=true;
      var startTime=(new Date()).getTime();
      this.pulsateInterval=setInterval(()=>{
        var newTime=(new Date()).getTime();
        var timePassed=newTime-startTime;
        startTime=newTime;
        var increment2=increment*timePassed/16;
        if (this.current+' '+this.frame!=current) {
          clearInterval(this.pulsateInterval);
          return;
        }
        if (direction) {
          this[property]+=increment2;
          if (this[property]>=max)
            direction=!direction;
        } else {
          this[property]-=increment2;
          if (this[property]<=min)
            direction=!direction;
        }
      }, 16);
    },
    doCough() {
      if (this.current!=7)
        return;
      this.coughProgress=0;
      setTimeout(()=>{
        if (this.current!=7)
          return;
        var startTime=(new Date()).getTime();
        var int=setInterval(()=>{
          var newTime=(new Date()).getTime();
          var timePassed=newTime-startTime;
          startTime=newTime;
          this.coughProgress+=0.16*timePassed/16;
          if (this.coughProgress>=3.14159265*4)
            clearInterval(int);
          this.cough=0.5-Math.cos(this.coughProgress)/2;
          if (this.coughProgress>=2 && !this.isCough)
            this.animateCough();
        }, 16);
      }, 2000);
    },
    animateCough() {
      this.isCough=true;
      setTimeout(()=>{
        this.isCough=false;
      }, 3000);
    },
  },
  watch: {
    frame(newFrame, oldFrame) {
      if (this.current==1 && newFrame>oldFrame && newFrame==4)
        this.animate("inflamation4", false, 1, 0.0022);
      if (this.current==1 && newFrame<oldFrame && oldFrame==4)
        this.animate("inflamation4", true, 0.0, 0.0022);
      if (this.current==1 && newFrame>oldFrame && newFrame==14)
        this.animate("inflamation", false, 0.3333, 0.03);
      if (this.current==1 && newFrame<oldFrame && oldFrame==14)
        this.animate("inflamation", true, 0.0, 0.03);

      if (this.current==6 && newFrame>oldFrame && newFrame==1)
        this.animate("inflamation", false, 0.6666, 0.006);
      if (this.current==6 && newFrame<oldFrame && oldFrame==1)
        this.animate("inflamation", true, 0.3333, 0.006);
      if (this.current==7 && newFrame>oldFrame && newFrame==1) {
        this.doCough();
        this.coughInterval=setInterval(()=>this.doCough(), 5000);
      }
    },
    current(newCurrent, oldCurrent) {
      if (newCurrent==2)
        this.pulsate("inflamation", 0.3333, 0.1666, 0.004);
      if (newCurrent==3 && oldCurrent<newCurrent)
        this.animate("inflamation", true, 0.3333, 0.03);
        
      if (newCurrent==4 && oldCurrent<newCurrent)
        this.animate("inflamation2", false, 0.5, 0.005);
      if (oldCurrent==4 && oldCurrent>newCurrent)
        this.animate("inflamation2", true, 0, 0.005);

      if (newCurrent==5 && oldCurrent<newCurrent)
        this.animate("inflamation2", false, 1, 0.005);
      if (oldCurrent==5 && oldCurrent>newCurrent)
        this.animate("inflamation2", true, 0.5, 0.005);

      if (newCurrent==6 && oldCurrent<newCurrent)
        this.animate("inflamation3", false, 1, 0.01);
      if (oldCurrent==6 && oldCurrent>newCurrent) {
        this.animate("inflamation3", true, 0, 0.01);
        this.animate("inflamation", true, 0.3333, 0.01);
      }
      if (newCurrent==7 && oldCurrent<newCurrent)
        this.animate("inflamation", false, 1, 0.003);
      if (oldCurrent==7 && oldCurrent>newCurrent) {
        this.animate("inflamation", true, 0.6666, 0.003);
        clearInterval(this.coughInterval);
      }
      // 8 ends the forward motion
      if (newCurrent==8 && oldCurrent<newCurrent) {
        this.animate("inflamation", true, 0, 0.03);
        clearInterval(this.coughInterval);
      }
      if (oldCurrent==8 && oldCurrent>newCurrent)
        this.animate("inflamation", false, 1, 0.03);
    },
  },
  computed: {
    mouseTransform() {
      var x=-this.mouseX;
      x=Math.sign(x)*Math.sqrt(Math.abs(x));
      var y=-this.mouseY;
      y=Math.sign(y)*Math.sqrt(Math.abs(y));
      x+=Math.cos(this.mouseStep)*10;
      y+=Math.sin(this.mouseStep*1.6)*10;
      return "translate("+x+"px, "+y+"px)";
    },
    stepClass() {
      var result="";
      for(var i=0;i<=this.current;i++)
        result+=" step-1-"+i;
      for(var i=0;i<=this.frame;i++)
        result+=" step-1-"+this.current+"-"+i;
      return result;
    },
  },
  mounted() {
    var elCircle=$(this.$refs.elCircle);
    $(document).on("mousemove.circle", (e)=>{
      var oE=e.originalEvent;
      this.mouseX=oE.clientX-elCircle.offset().left-100;
      this.mouseY=oE.clientY-elCircle.offset().top-100;
    });
    this.mouseStepInterval=setInterval(()=>{
      this.mouseStep+=0.02;
    }, 16);
  },
  destroyed() {
    $(document).off("mousemove.circle");
    clearInterval(this.mouseStepInterval);
  },
}
</script>
