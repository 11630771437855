var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "popup",
      on: {
        click: _vm.doClose,
        touchstart: function($event) {
          $event.stopPropagation()
        },
        touchmove: function($event) {
          $event.stopPropagation()
        },
        touchend: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "pop-inner",
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c("a", {
            staticClass: "close",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.doClose($event)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title-box" }, [
            _c("div", { staticClass: "img" }, [
              _c("img", { attrs: { src: _vm.person.img } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.person.name)),
              _c("span", [_vm._v(_vm._s(_vm.person.title))])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.person.bio) } })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }