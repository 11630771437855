<template>
  <div class="line" :style="lineStyle" />
</template>
<script>
export default {
  props: ["sx", "sy", "ex", "ey"],
  data: ()=>({
    width: 760,
    height: 640,
  }),
  computed: {
    lineStyle() {
      var pi=3.14159265;
      var sxP=this.sx/100*this.width;
      var syP=this.sy/100*this.height;
      var exP=this.ex/100*this.width;
      var eyP=this.ey/100*this.height;
      var width=Math.sqrt((sxP-exP)*(sxP-exP)+(syP-eyP)*(syP-eyP));
      var angle=0;
      if (syP!=eyP) {
        angle=Math.atan((eyP-syP)/(exP-sxP));
      }
      if (sxP>exP)
        angle+=pi;
      angle*=180/pi;
      return {
        left: sxP+"px", top: syP+"px",
        width: width+"px",
        transform: "rotate("+angle+"deg)",
      }
    },
  }
}
</script>
