<template>
  <transition name="fadebottom" mode="out-in">
  <ul class="progress" v-if="progressStep<3">
    <li :class="{active: progressStep==0}">
      <i></i>
      <em :style="{width: (progressStep==0?progressIn:1)*100+'%'}"></em>
      <span v-on:click="selectStep(0)">The allergic response</span>
    </li>
    <li :class="{active: progressStep==1}">
      <i></i>
      <em :style="{width: (progressStep==1?progressIn:progressStep>1?1:0)*100+'%'}"></em>
      <span v-on:click="selectStep(1)">Therapeutics</span>
    </li>
    <li :class="{active: progressStep==2}">
      <i></i>
      <em :style="{width: (progressStep==2?progressIn:progressStep>2?1:0)*100+'%'}"></em>
      <span v-on:click="selectStep(2)">Expertise</span>
    </li>
  </ul>
  <div v-else class="foot-social">
    <label>Share on</label>
    <a :href="'https://www.facebook.com/share.php?u='+href" class="fb" target="_blank">Facebook</a>
    <a :href="'https://twitter.com/intent/tweet?url='+href" class="tw" target="_blank">Twitter</a>
    <a :href="'mailto:?Subject=Food Allergy Science Initiative&body=FASI was launched to accelerate research into the science of food allergy and enable the development of new diagnostics and treatments. Discover how our work may lead to treatment - before an allergic reaction occurs:%0D%0A%0D%0A'+href+'%0D%0A%0D%0AFASI are researchers from the Broad Institute, its partner institutions, and Yale School of Medicine'" class="ig" target="_blank">Email</a>
  </div>
  </transition>
</template>

<script>
export default {
  props: ["progress"],
  computed: {
    progressStep() {
      return Math.floor(this.progress);
    },
    progressIn() {
      return this.progress%1;
    },
  },
  data: ()=>({
    href: window.location.href,
  }),
  methods: {
    selectStep(step) {
      infographic.step=step;
      infographic.current=0;
      infographic.frame=0;
    },
  }
}
</script>
