<template>
  <div class="text-content narrow">
    <p v-html="text.text"></p>
  </div>
</template>

<script>
export default {
  props: ["text"],
}
</script>
