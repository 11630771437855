var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        {
          attrs: {
            name: "fadeleft",
            duration: { enter: "5000", leave: "1000" }
          }
        },
        [
          _vm.contentLoaded && _vm.step == 0 && _vm.current < 1
            ? _c("main-text", {
                key: "step1a",
                attrs: { text: _vm.texts.step1a }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 2
            ? _c("div", { key: "step1y" })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 3
            ? _c("narrow-text", {
                key: "step1b",
                attrs: { text: _vm.texts.step1b }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 4
            ? _c("narrow-text", {
                key: "step1c",
                attrs: { text: _vm.texts.step1c }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 5
            ? _c("narrow-text", {
                key: "step1d",
                attrs: { text: _vm.texts.step1d }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 6
            ? _c("narrow-text", {
                key: "step1e",
                attrs: { text: _vm.texts.step1e }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 7
            ? _c("narrow-text", {
                key: "step1f",
                attrs: { text: _vm.texts.step1f }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 8
            ? _c("narrow-text", {
                key: "step1g",
                attrs: { text: _vm.texts.step1g }
              })
            : _vm.contentLoaded && _vm.step == 0 && _vm.current < 10
            ? _c("div", { key: "step1h", staticClass: "text-content list" }, [
                _c("ul", [
                  _c(
                    "li",
                    { class: { active: _vm.systemSelected == "nervous" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectSystem("nervous")
                            }
                          }
                        },
                        [_vm._v("Nervous System")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.systemSelected == "immune" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectSystem("immune")
                            }
                          }
                        },
                        [_vm._v("Immune System")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.systemSelected == "microbiome" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectSystem("microbiome")
                            }
                          }
                        },
                        [_vm._v("Microbiome")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { class: { active: _vm.systemSelected == "digestive" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectSystem("digestive")
                            }
                          }
                        },
                        [_vm._v("Digestive System")]
                      )
                    ]
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            name: "fadeplain",
            mode: "out-in",
            duration: { enter: "5000", leave: "1000" }
          }
        },
        [
          _vm.step == 0 && _vm.current == 9 && _vm.systemSelected == null
            ? _c(
                "div",
                { key: "nosystem", staticClass: "text-content-aside" },
                [
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(_vm.texts.step1systems.title)
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.texts.step1systems.items, function(item) {
                      return _c("li", { domProps: { innerHTML: _vm._s(item) } })
                    }),
                    0
                  )
                ]
              )
            : _vm.step == 0 && _vm.current == 9 && _vm.systemSelected != null
            ? _c(
                "div",
                {
                  key: "system" + _vm.systemSelected,
                  staticClass: "text-content-aside subsys",
                  on: {
                    touchstart: function($event) {
                      $event.stopPropagation()
                    },
                    touchmove: function($event) {
                      $event.stopPropagation()
                    },
                    touchend: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _c("h2", {
                    domProps: { innerHTML: _vm._s(_vm.currentSystem.text) }
                  }),
                  _vm._v(" "),
                  _c("h3", {
                    domProps: { innerHTML: _vm._s(_vm.currentSystem.title1) }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.currentSystem.items1, function(item) {
                      return _c("li", { domProps: { innerHTML: _vm._s(item) } })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("h3", {
                    domProps: { innerHTML: _vm._s(_vm.currentSystem.title2) }
                  }),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.currentSystem.items2, function(item) {
                      return _c("li", { domProps: { innerHTML: _vm._s(item) } })
                    }),
                    0
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }