var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadebottom", mode: "out-in" } }, [
    _vm.progressStep < 3
      ? _c("ul", { staticClass: "progress" }, [
          _c("li", { class: { active: _vm.progressStep == 0 } }, [
            _c("i"),
            _vm._v(" "),
            _c("em", {
              style: {
                width: (_vm.progressStep == 0 ? _vm.progressIn : 1) * 100 + "%"
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.selectStep(0)
                  }
                }
              },
              [_vm._v("The allergic response")]
            )
          ]),
          _vm._v(" "),
          _c("li", { class: { active: _vm.progressStep == 1 } }, [
            _c("i"),
            _vm._v(" "),
            _c("em", {
              style: {
                width:
                  (_vm.progressStep == 1
                    ? _vm.progressIn
                    : _vm.progressStep > 1
                    ? 1
                    : 0) *
                    100 +
                  "%"
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.selectStep(1)
                  }
                }
              },
              [_vm._v("Therapeutics")]
            )
          ]),
          _vm._v(" "),
          _c("li", { class: { active: _vm.progressStep == 2 } }, [
            _c("i"),
            _vm._v(" "),
            _c("em", {
              style: {
                width:
                  (_vm.progressStep == 2
                    ? _vm.progressIn
                    : _vm.progressStep > 2
                    ? 1
                    : 0) *
                    100 +
                  "%"
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.selectStep(2)
                  }
                }
              },
              [_vm._v("Expertise")]
            )
          ])
        ])
      : _c("div", { staticClass: "foot-social" }, [
          _c("label", [_vm._v("Share on")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "fb",
              attrs: {
                href: "https://www.facebook.com/share.php?u=" + _vm.href,
                target: "_blank"
              }
            },
            [_vm._v("Facebook")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "tw",
              attrs: {
                href: "https://twitter.com/intent/tweet?url=" + _vm.href,
                target: "_blank"
              }
            },
            [_vm._v("Twitter")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "ig",
              attrs: {
                href:
                  "mailto:?Subject=Food Allergy Science Initiative&body=FASI was launched to accelerate research into the science of food allergy and enable the development of new diagnostics and treatments. Discover how our work may lead to treatment - before an allergic reaction occurs:%0D%0A%0D%0A" +
                  _vm.href +
                  "%0D%0A%0D%0AFASI are researchers from the Broad Institute, its partner institutions, and Yale School of Medicine",
                target: "_blank"
              }
            },
            [_vm._v("Email")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }