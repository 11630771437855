<template>
  <div class="step step-3 animel" :class="stepClass">
    <div class="mesh-map animel">
      <!--<template v-for="person1 in people">
        <line-points :key="person1.img+person2.img" v-for="person2 in people" :sx="person1.pos[1]" :sy="person1.pos[0]" :ex="person2.pos[1]" :ey="person2.pos[0]" />
        <line-points :key="'k2'+person1.img+person2.img" v-for="person2 in people" :sx="person1.pos[1]+1.5" :sy="person1.pos[0]" :ex="person2.pos[1]" :ey="person2.pos[0]+1.5" />
      </template>-->
<!--       <canvas ref="canvas" width="760" height="640"></canvas> -->
      <canvas ref="canvas" width="1140" height="960"></canvas>
      <div class="person animel" :class="{board: person.type=='board'}" v-for="person in people" :style="{left: person.pos[1]+'%', top: person.pos[0]+'%'}" v-on:click.prevent="selectPerson(person)">
        <img :src="person.img" />
        <span>{{ person.name }}</span>
        <em>Bio</em>
      </div>
    </div>
  </div>
</template>
<script>
//import texts from '../texts.json';
texts=window.texts;
export default {
  props: ["contentLoaded", "current", "frame"],
  data: ()=>({
    peopleOrig: texts.step3members,
    progress: 10,
  }),
  computed: {
    people() {
      return this.peopleOrig.map((a, index)=>{
        var offsetWidth=this.current==0?Math.max(20-this.progress, 2):1;
        var offsetX=Math.cos(this.progress*(1+index*0.05))*offsetWidth;
        var offsetY=Math.sin(this.progress*(1+index*0.05))*offsetWidth;
        var newPos=[a.pos[0]+offsetX, a.pos[1]+offsetY];
        return {
          name: a.name, img: a.img,
          type: a.type, title: a.title,
          pos: newPos, bio: a.bio
        };
      });
    },
    stepClass() {
      var result="";
      for(var i=0;i<=this.current;i++)
        result+=" step-2-"+i;
      for(var i=0;i<=this.frame;i++)
        result+=" step-2-"+this.current+"-"+i;
      return result;
    },
  },
  mounted() {
    this.canvas=this.$refs.canvas;
    this.canvas2d=this.canvas.getContext("2d");
    console.log(this.canvas2d);
    this.lineMovement=setInterval(()=>{
      this.progress+=0.02;
      this.canvas2d.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvas2d.beginPath();
      for(var person1 of this.people)
        for(var person2 of this.people) {
          var sxP=person1.pos[1]/100*1140;
          var syP=person1.pos[0]/100*960;
          var exP=person2.pos[1]/100*1140;
          var eyP=person2.pos[0]/100*960;
          if (this.progress<12) {
            var scaleFactor=-1+this.progress/6;
            sxP=(sxP-570)*scaleFactor+570;
            exP=(exP-570)*scaleFactor+570;
            syP=(syP-480)*scaleFactor+480;
            eyP=(eyP-480)*scaleFactor+480;
          }
          this.canvas2d.moveTo(sxP, syP);
          this.canvas2d.lineTo(exP, eyP);
        }
      this.canvas2d.strokeStyle="#3bb2e5";
      var offsetWidth=this.current==0?2:1;
      this.canvas2d.lineWidth=offsetWidth;
      this.canvas2d.stroke();
    }, 30);
  },
  destroyed() {
    clearInterval(this.lineMovement);
  },
  methods: {
    selectPerson(person) {
      window.infographic.person=person;
    }
  },
}
</script>
