<template>
  <div>
    <transition name="fadeleft">
      <main-text key="step2a" :text="texts.step2a" v-if="step==1 && current<1"></main-text>
<!--       <div key="step2y" v-else-if="step==0 && current<2"></div> -->
      <narrow-text key="step2b" :text="texts.step2b" v-else-if="step==1 && current<2"></narrow-text>
      <narrow-text key="step2c" :text="texts.step2c" v-else-if="step==1 && current<3"></narrow-text>
      <narrow-text key="step2d" :text="texts.step2d" v-else-if="step==1 && current<4"></narrow-text>
    </transition>
    <div class="example-text" :class="{visible: step==1 && current==4}">
      <h2 v-html="title"></h2>
      <p v-html="text"></p>
    </div>
  </div>
</template>

<script>
//import texts from '../texts.json';
texts=window.texts;
export default {
  props: ["contentLoaded", "step", "current"],
  data: ()=>({
    texts: texts,
  }),
  computed: {
    title: function() {
      return this.texts.step2e.title.replace(/\n/g, "<br />\n");
    },
    text: function() {
      return this.texts.step2e.text.replace(/\n/g, "<br />\n");
    }
  },
}
</script>
