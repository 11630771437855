<template>
  <div>
    <transition name="fadeleft" :duration="{enter: '5000', leave: '1000'}">
      <main-text key="step1a" :text="texts.step1a" v-if="contentLoaded && step==0 && current<1"></main-text>
      <div key="step1y" v-else-if="contentLoaded && step==0 && current<2"></div>
      <narrow-text key="step1b" :text="texts.step1b" v-else-if="contentLoaded && step==0 && current<3"></narrow-text>
      <narrow-text key="step1c" :text="texts.step1c" v-else-if="contentLoaded && step==0 && current<4"></narrow-text>
      <narrow-text key="step1d" :text="texts.step1d" v-else-if="contentLoaded && step==0 && current<5"></narrow-text>
      <narrow-text key="step1e" :text="texts.step1e" v-else-if="contentLoaded && step==0 && current<6"></narrow-text>
      <narrow-text key="step1f" :text="texts.step1f" v-else-if="contentLoaded && step==0 && current<7"></narrow-text>
      <narrow-text key="step1g" :text="texts.step1g" v-else-if="contentLoaded && step==0 && current<8"></narrow-text>
      <div key="step1h" class="text-content list" v-else-if="contentLoaded && step==0 && current<10">
        <ul>
          <li :class="{active: systemSelected=='nervous'}"><a href="" v-on:click.prevent="selectSystem('nervous')">Nervous System</a></li>
          <li :class="{active: systemSelected=='immune'}"><a href="" v-on:click.prevent="selectSystem('immune')">Immune System</a></li>
          <li :class="{active: systemSelected=='microbiome'}"><a href="" v-on:click.prevent="selectSystem('microbiome')">Microbiome</a></li>
          <li :class="{active: systemSelected=='digestive'}"><a href="" v-on:click.prevent="selectSystem('digestive')">Digestive System</a></li>
        </ul>
      </div>
    </transition>
    
    <transition name="fadeplain" mode="out-in" :duration="{enter: '5000', leave: '1000'}">
    <div key="nosystem" class="text-content-aside" v-if="step==0 && current==9 && systemSelected==null">
      <h2 v-html="texts.step1systems.title"></h2>
      <ul>
        <li v-for="item in texts.step1systems.items" v-html="item"></li>
      </ul>
    </div>
    <div :key="'system'+systemSelected" class="text-content-aside subsys" v-else-if="step==0 && current==9 && systemSelected!=null"  v-on:touchstart.stop="" v-on:touchmove.stop="" v-on:touchend.stop="">
      <h2 v-html="currentSystem.text"></h2>
      <h3 v-html="currentSystem.title1"></h3>
      <ul>
        <li v-for="item in currentSystem.items1" v-html="item"></li>
      </ul>
      <h3 v-html="currentSystem.title2"></h3>
      <ul>
        <li v-for="item in currentSystem.items2" v-html="item"></li>
      </ul>
    </div>
    </transition>
  </div>
</template>

<script>
//import texts from '../texts.json';
texts=window.texts;
export default {
  props: ["contentLoaded", "step", "current", "systemSelected"],
  data: ()=>({
    texts: texts,
  }),
  watch: {
    current() {
      infographic.systemSelected=null;
    }
  },
  computed: {
    currentSystem() {
      if (this.systemSelected==null)
        return {};
      return this.texts["step1systems"+this.systemSelected];
    },
  },
  methods: {
    selectSystem(system) {
      window.infographic.systemSelected=system;
    }
  },
}
</script>
