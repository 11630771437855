var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg" }, [
    _c("div", { staticClass: "over1", class: { active: _vm.step > 0 } }, [
      _c("div", { staticClass: "over2", style: _vm.over2bg })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "over3", class: { active: _vm.step > 3 } }),
    _vm._v(" "),
    _c("div", { staticClass: "over4", class: { active: _vm.step > 4 } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }