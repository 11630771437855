var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step step-2 animel", class: _vm.stepClass },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "animel el-neuron n1" }, [
        _c(
          "div",
          [
            _c("step1-neuron", {
              staticClass: "animel",
              attrs: { inflamation: _vm.inflamation }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "animel el-icl i1" }, [
        _c(
          "div",
          [
            _c("step1-ilc", {
              staticClass: "animel",
              attrs: {
                inflamation: _vm.inflamation,
                handle: 1 - _vm.inflamation,
                peptide: true
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "animel el-neuron n2" }, [
        _c(
          "div",
          [
            _c("step1-neuron", {
              staticClass: "animel",
              attrs: { inflamation: _vm.inflamation }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "animel el-icl i2" }, [
        _c(
          "div",
          [
            _c("step1-ilc", {
              staticClass: "animel",
              attrs: {
                inflamation: _vm.inflamation,
                handle: 1 - _vm.inflamation,
                peptide: true
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "animel el-neuron n3" }, [
        _c(
          "div",
          [
            _c("step1-neuron", {
              staticClass: "animel",
              attrs: { inflamation: _vm.inflamation }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "animel el-dots" }, [
      _c("em"),
      _c("em"),
      _c("em"),
      _c("em"),
      _c("em"),
      _c("em")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pill" }, [
      _c("div", { staticClass: "animel pill-dot d1" }),
      _vm._v(" "),
      _c("div", { staticClass: "animel pill-dot d2" }),
      _vm._v(" "),
      _c("div", { staticClass: "animel pill-dot d3" }),
      _vm._v(" "),
      _c("div", { staticClass: "animel pill-half h1" }),
      _vm._v(" "),
      _c("div", { staticClass: "animel pill-half h2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }