import { render, staticRenderFns } from "./PopupPerson.vue?vue&type=template&id=316d3a9b&"
import script from "./PopupPerson.vue?vue&type=script&lang=js&"
export * from "./PopupPerson.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/data/Projects/fasi/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('316d3a9b')) {
      api.createRecord('316d3a9b', component.options)
    } else {
      api.reload('316d3a9b', component.options)
    }
    module.hot.accept("./PopupPerson.vue?vue&type=template&id=316d3a9b&", function () {
      api.rerender('316d3a9b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "static/jssrc/sect3/PopupPerson.vue"
export default component.exports