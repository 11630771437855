var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-content onpg1" }, [
    _c("h1", { domProps: { innerHTML: _vm._s(_vm.text.title) } }),
    _vm._v(" "),
    _vm.text.subtitle
      ? _c("h2", { domProps: { innerHTML: _vm._s(_vm.text.subtitle) } })
      : _vm._e(),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.text.text) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }