var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fadeleft" } },
        [
          _vm.step == 2 && _vm.current < 1
            ? _c("main-text", {
                key: "step3a",
                attrs: { text: _vm.texts.step3a }
              })
            : _vm.step == 2
            ? _c("div", { staticClass: "text-content sections" }, [
                _c("div", { staticClass: "sec1" }, [
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.texts.step3b.title1) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "sec-links" }, [
                    _c("a", {
                      attrs: { href: "" },
                      domProps: { innerHTML: _vm._s(_vm.texts.step3b.type1) },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sec2" }, [
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.texts.step3b.title2) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "sec-links" }, [
                    _c("a", {
                      attrs: { href: "" },
                      domProps: { innerHTML: _vm._s(_vm.texts.step3b.type2) },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sec-links" }, [
                    _c("a", {
                      attrs: { href: "" },
                      domProps: { innerHTML: _vm._s(_vm.texts.step3b.type2a) },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    })
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }