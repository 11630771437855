var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "human", class: { cough: _vm.isCough } },
    [
      !_vm.intestine
        ? _c(
            "svg",
            {
              attrs: {
                width: "268px",
                height: "627px",
                viewBox: "0 0 268 627",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                id: "silouette"
              }
            },
            [
              _c("defs", [
                _c(
                  "filter",
                  { attrs: { id: "inset-shadow" } },
                  [
                    _c("feOffset", { attrs: { dx: "0", dy: "0" } }),
                    _vm._v(" "),
                    _c("feGaussianBlur", {
                      attrs: { stdDeviation: "10", result: "offset-blur" }
                    }),
                    _vm._v(" "),
                    _c("feComposite", {
                      attrs: {
                        operator: "out",
                        in: "SourceGraphic",
                        in2: "offset-blur",
                        result: "inverse"
                      }
                    }),
                    _vm._v(" "),
                    _c("feFlood", {
                      attrs: {
                        "flood-color": _vm.floodColor,
                        "flood-opacity": "1",
                        result: "color"
                      }
                    }),
                    _vm._v(" "),
                    _c("feComposite", {
                      attrs: {
                        operator: "in",
                        in: "color",
                        in2: "inverse",
                        result: "shadow"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("clipPath", { attrs: { id: "silhouette-clip" } }, [
                _c("path", { attrs: { d: _vm.points } })
              ]),
              _vm._v(" "),
              _c(
                "g",
                {
                  attrs: {
                    id: "anim",
                    stroke: "none",
                    "stroke-width": "1",
                    fill: "none",
                    "fill-rule": "evenodd",
                    opacity: "1"
                  }
                },
                [
                  _c(
                    "g",
                    {
                      staticClass: "animel",
                      attrs: {
                        id: "Body-changing",
                        transform: "translate(-48.000000, -62.000000)",
                        fill: "#2e65b2"
                      }
                    },
                    [_c("path", { attrs: { d: _vm.points, id: "silhouette" } })]
                  ),
                  _vm._v(" "),
                  _vm.inflamation > 0.66
                    ? _c(
                        "g",
                        {
                          staticClass: "animel2",
                          attrs: {
                            id: "Body-changing2",
                            transform: "translate(-48.000000, -62.000000)",
                            fill: "rgba(0,0,0,0)",
                            stroke: "#ef3c3e",
                            "stroke-width": "6px",
                            opacity: _vm.inflamation * 3 - 2,
                            "clip-path": "url(#silhouette-clip)"
                          }
                        },
                        [
                          _c("path", {
                            attrs: { d: _vm.points, id: "silhouette2" }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.intestine
        ? _c("step1-digestive", {
            staticClass: "animel human-digestive",
            attrs: { inflamation: _vm.inflamation4 }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.intestine
        ? _c("step1-small-intestine", {
            staticClass: "animel human-sintestine",
            attrs: { progress: _vm.inflamation }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.intestine
        ? _c("step1-large-intestine", {
            staticClass: "animel human-lintestine",
            attrs: { progress: _vm.inflamation }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.intestine
        ? _c("div", { staticClass: "cough" }, [
            _c("em"),
            _c("span", [_vm._v("Cough")])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.intestine
        ? _c("div", { staticClass: "cough cough2" }, [
            _c("em"),
            _c("span", [_vm._v("Cough")])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.intestine
        ? [
            _c("div", {
              staticClass: "animel detail-nervous",
              class: {
                hidden: _vm.systemSelected && _vm.systemSelected != "nervous"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-nervous dn2",
              class: {
                hidden: _vm.systemSelected && _vm.systemSelected != "nervous"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-immune",
              class: {
                hidden: _vm.systemSelected && _vm.systemSelected != "immune"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-immune dn2",
              class: {
                hidden: _vm.systemSelected && _vm.systemSelected != "immune"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-microbiome",
              class: {
                hidden:
                  _vm.systemSelected &&
                  _vm.systemSelected != "microbiome" &&
                  _vm.systemSelected != "digestive"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-microbiome dn2",
              class: {
                hidden:
                  _vm.systemSelected &&
                  _vm.systemSelected != "microbiome" &&
                  _vm.systemSelected != "digestive"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-digestive",
              class: {
                hidden: _vm.systemSelected && _vm.systemSelected != "digestive"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "animel detail-digestive dn2",
              class: {
                hidden: _vm.systemSelected && _vm.systemSelected != "digestive"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }