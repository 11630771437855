var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step step-3 animel", class: _vm.stepClass },
    [
      _c(
        "div",
        { staticClass: "mesh-map animel" },
        [
          _c("canvas", {
            ref: "canvas",
            attrs: { width: "1140", height: "960" }
          }),
          _vm._v(" "),
          _vm._l(_vm.people, function(person) {
            return _c(
              "div",
              {
                staticClass: "person animel",
                class: { board: person.type == "board" },
                style: { left: person.pos[1] + "%", top: person.pos[0] + "%" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectPerson(person)
                  }
                }
              },
              [
                _c("img", { attrs: { src: person.img } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(person.name))]),
                _vm._v(" "),
                _c("em", [_vm._v("Bio")])
              ]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }