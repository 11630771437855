<template>
  <div class="end-message" :class="{active: active}">
    <div>
      <div class="fline">{{ texts.endText1 }}</div>
      <div class="sline">{{ texts.endText2 }}</div>
      <a href="https://giving.broadinstitute.org/fasi" target="_blank" class="target" :class="{visible: targetVisible}">Learn more</a>
    </div>
  </div>
</template>
<script>
texts=window.texts;
export default {
  data: ()=>({
    active: false,
    targetVisible: false,
    lettersVisible: 0,
    texts: texts
  }),
  mounted() {
    setTimeout(()=>{
      var letterInterval=setInterval(()=>{
        this.lettersVisible++;
        if (this.lettersVisible>23)
          clearInterval(letterInterval);
      }, 100);
    }, 1200);
    setTimeout(()=>this.active=true, 1000);
    setTimeout(()=>this.targetVisible=true, 3500);
  },
}
</script>
