var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step step-1 animel", class: _vm.stepClass },
    [
      _c("human-figure", {
        staticClass: "animel el-human",
        attrs: {
          intestine: false,
          inflamation: _vm.inflamation,
          progress: _vm.cough,
          isCough: _vm.isCough,
          systemSelected: _vm.systemSelected,
          inflamation4: _vm.inflamation4
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "animel el-egg",
        class: { loaded: _vm.contentLoaded }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "animel el-milk",
        class: { loaded: _vm.contentLoaded }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "animel el-peanut",
        class: { loaded: _vm.contentLoaded }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "animel el-peanut-1",
        class: { loaded: _vm.contentLoaded }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "animel el-peanut-2",
        class: { loaded: _vm.contentLoaded }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "animel el-peanut-3",
        class: { loaded: _vm.contentLoaded }
      }),
      _vm._v(" "),
      _c("human-figure", {
        staticClass: "animel el-human",
        attrs: {
          intestine: true,
          inflamation: _vm.inflamation,
          progress: _vm.cough,
          isCough: _vm.isCough,
          systemSelected: _vm.systemSelected
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "animel el-dot" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "el-triangle-rotate" },
        [_c("step1-triangle", { staticClass: "animel el-triangle" })],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "elCircle", staticClass: "animel el-circle" }, [
        _c(
          "div",
          {
            staticClass: "animel el-lining",
            style: { transform: _vm.mouseTransform }
          },
          [_c("span", [_vm._v("Gut Lining")]), _c("em")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "animel el-food",
            style: { transform: _vm.mouseTransform }
          },
          [_vm._m(0), _c("em")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "animel el-neuron",
            style: {
              transform:
                _vm.mouseTransform + " translate(60px, 40px) scale(1.4)"
            }
          },
          [
            _c("span", [_vm._v("Neuron")]),
            _vm._v(" "),
            _c("step1-neuron", {
              staticClass: "animel",
              attrs: { inflamation: _vm.inflamation2 }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "animel el-peptide",
            style: { transform: _vm.mouseTransform }
          },
          [_vm._m(1), _c("em")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "animel el-icl",
            style: { transform: _vm.mouseTransform }
          },
          [
            _c("span", [_vm._v("ILC2 Cell")]),
            _vm._m(2),
            _vm._v(" "),
            _c("step1-ilc", {
              staticClass: "animel",
              attrs: { inflamation: _vm.inflamation3 }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Food"), _c("br"), _vm._v("Allergens")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("Neuro"), _c("br"), _vm._v("Peptide")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "animel" }, [
      _vm._v("Neuro"),
      _c("br"),
      _vm._v("Peptide"),
      _c("br"),
      _vm._v("Receptor")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }