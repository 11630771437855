<template>
  <div class="container" :style="{transform: ' translate(-50%, -50%) scale('+scale+')', 'transform-origin': '50% 50%'}" :class="{'final-step': step==3}">
    <div class="logo" :class="{visible: initDone}">FOOD ALLERGY SCIENCE INITIATIVE</div>
    <footer :class="{visible: initDone}">
      <a href="" class="scroll" :class="{visible: progress==0, back: step==3}" v-on:click.prevent="step==3?step=0:nextStep()"><span></span>{{ step==3?'Beginning':'Scroll' }}</a>
      <progress-bar :progress="progress"></progress-bar>
      <a href="https://giving.broadinstitute.org/fasi" target="_blank" class="lmore"><span></span>Learn more</a>
    </footer>
    <a class="close" :class="{visible: initDone, active: closeActive}" v-on:click.prevent="doClose">
      <em></em>
      <span>Are you sure you'd like to quit?</span>
    </a>
    
    <step1-titles :contentLoaded="contentLoaded" :step="step" :current="current" :systemSelected="systemSelected"></step1-titles>
    <step2-titles :contentLoaded="contentLoaded" :step="step" :current="current"></step2-titles>
    <step3-titles :contentLoaded="contentLoaded" :step="step" :current="current"></step3-titles>
    <transition name="stepsanim" mode="out-in">
      <step1-elements v-if="step==0" :contentLoaded="contentLoaded" :current="current" :frame="frame" :systemSelected="systemSelected" />
      <step2-elements v-else-if="step==1" :contentLoaded="contentLoaded" :current="current" :frame="frame" />
      <step3-elements v-else-if="step==2" :current="current" :frame="frame" />
    </transition>
    
    
    <step-final v-if="step==3" />
    
    <transition name="popupanim">
      <popup-person v-if="person!=null" :person="person"></popup-person>
      
    </transition>
  </div>
</template>

<script>
//import texts from './texts.json';
texts=window.texts;
import $ from 'jquery';
export default {
  data: ()=>({
    scale: 1,
    step: 0,
    current: 0,
    frame: 0,
    steps: [
      {pages: [
        {frames: [1000]},
//         {autoNext: true, frames: [1000, 1000, 1000, 600, 400, 1500, 300, 300, 500, 200, 200, 200, 350, 350]},
        {autoNext: true, frames: [1000, 1000, 1000, 600, 400, 1500, 800, 800, 800, 500, 500, 500, 700, 700]},
        {frames: [1000]},
        {frames: [1000]},
        {frames: [1000]},
        {frames: [1000]},
        {frames: [1000, 1000]},
        {frames: [1000, 1000]},
        {autoNext: true, frames: [1000, 1000, 1000, 1000, 1000, 1000]},
        {frames: [1000]},
      ]},
      {pages: [
        {frames: [1000]},
        {frames: [1000]},
        {frames: [800, 600, 2000, 500]},
        {frames: [1000]},
        {frames: [1000]},
      ]},
      {pages: [
        {frames: [1000]},
        {frames: [1000]},
      ]},
      {pages: [
        {frames: [1000]},
      ]},
    ],
    initDone: false,
    contentLoaded: false,
    systemSelected: null,
    person: null,
    closeActive: false,
  }),
  computed: {
    progress() {
      return this.step+this.current/this.steps[this.step].pages.length;
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler() {
        if (window.igBackground) {
          if (this.step==0)
            window.igBackground.step=0;
          if (this.step==1)
            window.igBackground.step=1;
          if (this.step==1 && this.current>=2)
            window.igBackground.step=2;
          if (this.step==1 && this.current>=3)
            window.igBackground.step=3;
          if (this.step==2)
            window.igBackground.step=4;
          if (this.step==3)
            window.igBackground.step=5;
        }
      },
    },
    step(oldVal, newVal) {
      this.makeFrame(oldVal<newVal);
    },
    current(oldVal, newVal) {
      this.makeFrame(oldVal<newVal);
    },
    frame(oldVal, newVal) {
      this.makeFrame(oldVal<newVal);
    },
  },
  methods: {
    makeFrame(backwards) {
      var s=this.step;
      var c=this.current;
      var f=this.frame;
      if (backwards) {
        f++;
        if (f==this.steps[this.step].pages[this.current].frames.length) {
          f=0;
          c++;
          if (c==this.steps[this.step].pages.length) {
            c=0;
            s++;
          }
        }
      }
      var step=this.steps[s].pages[c];
      var frameTime=((step.frames || [1000])[f]/1000)-0.03;
      console.log(frameTime);
      $(this.$el).find(".animel").css("transition", (frameTime*0.97)+"s linear");
    },
    doClose() {
      window.parent.postMessage({fasiBannerDeactivate: true}, "*");
    },
    nextStep() {
      if (this.steps[this.step].pages.length>this.current+1)
        this.current++;
      else if (this.steps.length>this.step+1) {
        this.current=0;
        this.step++;
      }
      this.frame=0;
      var step=this.steps[this.step].pages[this.current];
      step.frames=step.frames || [1000];
      var accTime=0;
      for(var i=0;i<step.frames.length;i++) {
        accTime+=step.frames[i];
        setTimeout(()=>{
          if (this.frame<step.frames.length-1)
            this.frame++;
          else {
            if (step.autoNext)
              this.nextStep();
            else
              this.wheelActive=false;
          }
        }, accTime);
      }
    },
    prevStep() {
      if (this.current)
        this.current--;
      else if (this.step) {
        this.step--;
        this.current=this.steps[this.step].pages.length-1;
      }
      var step=this.steps[this.step].pages[this.current];
      console.log(step);
      this.frame=step.frames?step.frames.length-1:0;
      step.frames=step.frames || [1000];
      var accTime=0;
      for(var i=step.frames.length;i>=1;i--) {
        if (i==step.frames.length) {
          var s=this.step;
          var c=this.current;
          var f=this.frame;
          f++;
          if (f==this.steps[this.step].pages[this.current].frames.length) {
            f=0;
            c++;
            if (c==this.steps[this.step].pages.length) {
              c=0;
              s++;
            }
          }
          accTime+=this.steps[s].pages[c].frames[f];
        } else {
          accTime+=step.frames[i];
        }
        setTimeout(()=>{
          if (this.frame)
            this.frame--;
          else {
            if (step.autoNext)
              this.prevStep();
            else
              this.wheelActive=false;
          }
        }, accTime);
      }
    },
  },
  mounted() {
    window.scrollTop=1;
    window.infographic=this;
    $(window).on("resize.igscale", ()=>{
      if (window.innerWidth<=990 && window.innerWidth<window.innerHeight)
        this.scale=Math.min(window.innerWidth/576, window.innerHeight/1024);
      else
        this.scale=Math.min(window.innerWidth/1024, window.innerHeight/768);
    }).trigger("resize.igscale");
    $(window).on("wheel", (e)=>{
      if (this.person)
        return;
      if (e.originalEvent.deltaY>0 && !this.wheelActive) {
        this.wheelActive=true;
        this.nextStep();
      } else if (e.originalEvent.deltaY<0 && !this.wheelActive) {
        this.wheelActive=true;
        this.prevStep();
        setTimeout(()=>this.wheelActive=false, 1000);
      }
    });
    var point=null;
    $(window).on("touchstart", (e)=>{
      point={x: e.originalEvent.touches[0].pageX,
             y: e.originalEvent.touches[0].pageY};
      console.log(point);
    });
    $(window).on("touchmove", (e)=>{
      if (point) {
        var newPoint={x: e.originalEvent.touches[0].pageX,
                      y: e.originalEvent.touches[0].pageY};
        console.log("new", newPoint);
        if (newPoint.y<point.y-30) {
          this.wheelActive=true;
          this.nextStep();
          point=null;
        }
        if (newPoint.y>point.y+30) {
          this.wheelActive=true;
          this.prevStep();
          point=null;
        }
      }
    });
    $(window).on("touchend", (e)=>{
      point=null;
    });
    $(window).on("keyup", (e)=>{
      if (this.person)
        return;
      if (!this.wheelActive)
      if (e.originalEvent.key=="ArrowDown" ||
          e.originalEvent.key=="PageDown") {
        this.wheelActive=true;
        this.nextStep();
      }
      if (!this.wheelActive)
      if (e.originalEvent.key=="ArrowUp" ||
          e.originalEvent.key=="PageUp") {
        this.wheelActive=true;
        this.prevStep();
      }
    });
    setTimeout(()=>this.initDone=true, 1000);
    setTimeout(()=>this.contentLoaded=true, 2000);
  },
}
</script>
