import Vue from 'vue';
import $ from 'jquery';


window.igColorMix=function(color1, color2, mix) {
  color1=color1.replace("#", "");
  color2=color2.replace("#", "");
  var r1=Number.parseInt(color1.substr(0, 2), 16);
  var r2=Number.parseInt(color2.substr(0, 2), 16);
  var g1=Number.parseInt(color1.substr(2, 2), 16);
  var g2=Number.parseInt(color2.substr(2, 2), 16);
  var b1=Number.parseInt(color1.substr(4, 2), 16);
  var b2=Number.parseInt(color2.substr(4, 2), 16);
  var r=Math.round(r1+(r2-r1)*mix);
  var g=Math.round(g1+(g2-g1)*mix);
  var b=Math.round(b1+(b2-b1)*mix);
  r=r.toString(16);
  r=(r.length<2?"0":"")+r;
  g=g.toString(16);
  g=(g.length<2?"0":"")+g;
  b=b.toString(16);
  b=(b.length<2?"0":"")+b;
  return "#"+r+g+b;
}


var vueComponentList=[
  require.context(".", false, /.+\.vue$/),
  require.context("./sect1", false, /.+\.vue$/),
  require.context("./sect2", false, /.+\.vue$/),
  require.context("./sect3", false, /.+\.vue$/),
];
for(var vueComponentListItem of vueComponentList)
  for(var key of vueComponentListItem.keys()) {
    Vue.component(key.replace(/\.vue|.*\//g, ""), vueComponentListItem(key).default);
  }

$("[vue-dyn]").each(function() {
  new Vue({
    el: this,
  });
});

$(document).on("touchmove", function(e) {
  e.preventDefault();
});

new Image("static/img/animsvg/00-peanut-2.svg");


$(".container").click(function(e) {
  if ($(e.target).closest("a").length)
    return;
  if ($(e.target).closest(".person").length)
    return;
  if ($(e.target).closest(".popup").length)
    return;
  if ($(e.target).closest("footer").length)
    return;
  infographic.nextStep();
});
