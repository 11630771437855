<template>
  <div class="popup" v-on:click="doClose" v-on:touchstart.stop="" v-on:touchmove.stop="" v-on:touchend.stop="">
    <div class="pop-inner" v-on:click.stop="">
      <a href="" class="close" v-on:click.prevent="doClose"></a>
      <div class="title-box">
        <div class="img"><img :src="person.img" /></div>
        <div class="name">{{ person.name }}<span>{{ person.title }}</span></div>
      </div>
      <div class="detail">
        <p v-html="person.bio"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["person"],
  methods: {
    doClose() {
      window.infographic.person=null;
    },
  }
}
</script>
