<template>
  <div>
    <div class="el-peptide" v-if="peptide" :style="{opacity: 1-(handle || 0), transform: 'translate('+((handle||0)*-40)+'px, '+((handle||0)*0)+'px'}"></div>
  <svg width="107px" height="73px" viewBox="0 0 107 73" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <radialGradient cx="0%" cy="66.9913972%" fx="0%" fy="66.9913972%" r="113.142418%" gradientTransform="translate(0.000000,0.669914),scale(0.844828,1.000000),rotate(145.728014),translate(-0.000000,-0.669914)" id="radialGradient-ilc">
            <stop :stop-color="startColor" offset="0%"></stop>
            <stop :stop-color="stopColor" offset="45.6647399%"></stop>
            <stop stop-color="#EEDC2C" offset="100%"></stop>
        </radialGradient>
    </defs>
    <g id="ILC2-cell" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(0.899346, 0.676915)">
            <g :transform="handleTransform">
              <path d="M80.4245818,24.1312641 L87.4939909,20.7080484 C87.4939909,20.7080484 86.0434109,13.8580915 89.3626738,11.1117629 C92.6810237,8.36455298 98.3984448,6.51809652 96.0276732,8.80611608 C93.6578145,11.095017 91.0186536,14.1321955 91.760832,18.4200288 C92.211799,21.0218137 96.2878464,22.0062967 98.6065834,21.526835 C101.182755,20.9927288 103.378252,17.3456467 104.92925,17.3385958 C106.48116,17.3315449 102.610513,24.2097054 99.4856951,25.5070724 C96.4375602,26.771829 91.20397,25.4400888 91.20397,25.4400888 L83.0272271,31.1698327 L80.4245818,24.1312641 Z" id="Fill-5" fill="#EEDC2C"></path>
            </g>
            <path d="M0.0740280682,38.4202238 C-1.2146445,24.2901039 14.3042246,-6.03438267 47.9832392,1.0598153 C81.6613404,8.1531027 91.3195349,39.3872419 80.7124171,54.9724418 C71.6999289,68.2147626 61.7394307,72.470735 40.3927659,71.5246599 C19.0479278,70.5785847 1.97096139,59.2293248 0.0740280682,38.4202238" id="Fill-1" fill="url(#radialGradient-ilc)"></path>
            <path d="M33.1945613,34.4786239 C32.5702137,28.5090977 40.5076987,14.0279132 56.4181122,18.6939641 C66.201376,21.561965 73.996179,27.5163964 74.1034178,37.3057801 C74.1797572,44.3417054 63.0869083,48.8656882 52.7392778,48.4661215 C42.3916473,48.0665548 34.1142699,43.2708667 33.1945613,34.4786239" id="Fill-3" fill="#F9F7C0"></path>
        </g>
    </g>
  </svg>
    <div class="immune-response animel">
      <strong v-for="i in [0,1,2,3,4,5,6,7,8]" :style="{transform: 'rotate('+i*36+'deg)'}">
        <em :style="{display: 'block', transform: imTransform, opacity: imOpacity}">Immune Response</em>
      </strong>
    </div>
  </div>
</template>
<script>
export default {
  props: ["inflamation", "peptide", "handle"],
  data: ()=>({
    improgress: 0,
  }),
  computed: {
    handleTransform() {
      var x=-40*(this.handle||0);
      var y=10*(this.handle||0);
      return "translate("+x+", "+y+")";
    },
    startColor() {
      return window.igColorMix("#EEDC2C", "#FB174B", Math.min(1, Math.max(0, this.inflamation*3-1)));
    },
    stopColor() {
      return window.igColorMix("#EEDC2C", "#F47A3B", Math.min(1, Math.max(0, this.inflamation*3-2)));
    },
    imOpacity() {
      if (this.improgress<20)
        return this.improgress/20;
      else
        return (50-this.improgress)/30;
    },
    imTransform() {
      return "scale("+(1+this.improgress/100)+") translateX("+((this.improgress-15)*2)+"px)";
    },
  },
  mounted() {
    var startTime=(new Date()).getTime();
    this.improgressInt=setInterval(()=>{
      var newTime=(new Date()).getTime();
      var timePassed=newTime-startTime;
      startTime=newTime;
      this.improgress+=timePassed/16;
      this.improgress%=50;
    }, 16);
  },
  destroyed() {
    clearInterval(this.improgressInt);
  },
}
</script>
